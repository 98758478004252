$darkThemeFirstColor: #171717;
$darkThemeSecondColor: #282828;
$darkThemeThirdColor: #4c4c4c;
$darkThemeForthColor: #262c2f;

$primaryColor: #A65A15;
$secondaryColor: #CF701A;
// $secondaryColor: #009efd;
// $secondaryColor: #2af598;

$topNavigationBarHeight: 35px;
$stateBarHeight: 2px;
$sideNavigationBarCompanionWidth: 23px;
$sideNavigationBarContentWidth: 300px;
$editorBottomNavigationBarHeight: 40px;
$editorTopNavigationBarHeight: 40px;
$toolboxWidth: 50px;